
import { API_ROOT_URL } from '~/constants/index'

export default {
  props: {
    newsList: {
      type: Array,
      default: () => {}
    }
  },

  data: () => ({
    API_ROOT_URL
  })
}
