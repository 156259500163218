
import TopBanner from '~/components/organisms/home/TopBanner.vue'
import News from '~/components/organisms/home/News.vue'
import Event from '~/components/organisms/home/Event.vue'
import OfficialSponsor from '~/components/organisms/home/OfficialSponsor.vue'
import OfficialSupporter from '~/components/organisms/home/OfficialSupporter.vue'

export default {
  layout: 'user',

  components: { TopBanner, News, Event, OfficialSponsor, OfficialSupporter },

  beforeMount() {
    this.scrollToTop()
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
