
import NewsList from '../NewsList.vue'

export default {
  components: { NewsList },
  data: () => ({
    newsList: []
  }),

  created() {
    this.getNewsList()
  },

  methods: {
    /**
     * Get news
     */
    async getNewsList() {
      const params = { limit: 6 }
      const { data } = await this.$api.news.list({ params })
      this.newsList = data?.result?.data
    }
  }
}
