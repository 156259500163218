/**
 * Format full date
 */
export const FORMAT_DATE_FULL = 'YYYY-MM-DD'

/**
 * Format full date time
 */
export const FORMAT_DATE_TIME_FULL = 'YYYY-MM-DD HH:mm:ss'

/**
 * Format time
 */
export const FORMAT_TIME = 'HH:mm'

/**
 * Time zone
 */
export const DEFAULT_TIMEZONE = 'Asia/Tokyo'

/**
 * Language
 */
export const DEFAULT_LANGUAGE = 'ja-JP'

/**
 * API ROOT URL
 */
export const API_ROOT_URL = process.env.API_ROOT_URL

/**
 * DEFAULT NEWS PAGING LIMIT PER PAGE
 */
export const NEWS_PAGING_LIMIT = 9

/**
 * HOME ROOT URL
 */
export const HOME_URL = process.env.HOME_URL
